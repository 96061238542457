<template>
    <el-drawer v-model="show" title="Filtro Ranking dos Colaboradores">
        <div class='row'>
            <div class='col-sm-12'>
                <text-input
                    type='date'
                    label='Data Inicio'
                    v-model='formFilter.start_date'
                />
            </div>
            <div class='col-sm-12'>
                <text-input
                    type='date'
                    label='Data Fim'
                    v-model='formFilter.end_date'
                />
            </div>
            <div class='col-sm-12'>
                <select-input
                    label='Tipo de Avaliação'
                    v-model='formFilter.type'
                    :options='[{id: "DESEMPENHO", name: "Avalição de Desempenho"}, {id: "EXPERIENCIA", name: "Avalição de Experiência"}]'
                />
            </div>
            <div class='col-sm-12'>
                <select-input
                    label='Modo de Avaliação'
                    v-model='formFilter.mode'
                    :options='[{id: 90, name: "90° graus"}, {id: 180, name: "180° graus"}, {id: 360, name: "360° graus"}]'
                />
            </div>
            <div class='col-sm-12'>
                <select-multi-input
                    label='Cargo(s)'
                    v-model='formFilter.positions'
                    :options='positions'
                    placeholder='Todos cargos'
                />
            </div>
            <div class='col-sm-12'>
                <select-multi-input
                    label='Setor(es)'
                    v-model='formFilter.sectors'
                    :options='sectors'
                    placeholder='Todos setores' />
            </div>
            <div class='col-sm-12'>
                <select-multi-input
                    label='Unidade(s)'
                    v-model='formFilter.companies'
                    :options='companies'
                    placeholder='Todas unidades'
                />
            </div>
            <div class='col-sm-12'>
                <app-button variant='success' icon='fa fa-search' text='Pesquisar' class='w-100'
                            style='margin-top: 33px' @click.prevent='onFormFilter' />
            </div>
        </div>
    </el-drawer>
</template>

<script setup>
    import SelectInput from '@/components/SelectInput.vue';
    import AppButton from '@/components/AppButton.vue';
    import { computed, ref, toRaw } from 'vue';
    import TextInput from '@/components/TextInput.vue';
    import moment from 'moment';
    import SelectMultiInput from '@/components/SelectMultiInput.vue';

    const formFilter = ref({
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        sectors: [],
        companies: [],
        positions: [],
        type: null,
        mode: null
    });

    const emit = defineEmits(['onFilter', 'update:modelValue']);
    
    const props = defineProps({
        positions: { required: true }, 
        sectors: { required: true },
        companies: { required: true },
        modelValue: { required: true }
    });

    const show = computed({
        get: () => props.modelValue,
        set: (value) => emit('update:modelValue', value)
    });

    const onFormFilter = () => {
        emit('update:modelValue', false);
        emit('onFilter', filters.value);
    };

    const filters = computed(() => {
        let endpoint = ``;
        if (formFilter.value.start_date) {
            endpoint += `&start_date=${formFilter.value.start_date}`;
        }

        if (formFilter.value.end_date) {
            endpoint += `&end_date=${formFilter.value.end_date}`;
        }

        if (formFilter.value.type) {
            endpoint += `&type=${formFilter.value.type}`;
        }

        if (formFilter.value.mode) {
            endpoint += `&mode=${formFilter.value.mode}`;
        }

        if (formFilter.value.sectors.length) {
            endpoint += `&sectors=${formFilter.value.sectors?.map(s => s.id).join(',')}`;
        }

        if (formFilter.value.companies.length) {
            endpoint += `&companies=${formFilter.value.companies?.map(c => c.id).join(',')}`;
        }

        if (formFilter.value.positions.length) {
            endpoint += `&positions=${formFilter.value.positions?.map(c => c.id).join(',')}`;
        }

        return endpoint;
    });
</script>

<style scoped>

</style>
