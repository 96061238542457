import { createStore } from 'vuex';
import i18n from '../i18n';
import user from '@/store/user';
import positionDescription from '@/store/positionDescription';
import descriptionAnalysis from '@/store/descriptionAnalysis';
import salarySurvey from '@/store/salarySurvey';
import suitabilityTable from '@/store/suitabilityTable';
import collaborators from '@/store/collaborators';
import evaluations from '@/store/evaluations';
import sectors from '@/store/sectors';
import positions from '@/store/positions';
import { useToast } from 'vue-toastification';
import { h, ref } from 'vue';
import { formatNumberBr, handleAxiosError, justNumbers } from '@/helpers';
import transactionalNps from './transactionalNps';
import relationalNps from './relationalNps';
import myReviews from './myReviews';

export default new createStore({
    state: {
        layout: 'auth',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'light',
        locale: null,
        menu_style: 'vertical',
        layout_style: 'full',
        countryList: [
            { code: 'zh', name: 'Chinese' },
            { code: 'da', name: 'Danish' },
            { code: 'en', name: 'English' },
            { code: 'fr', name: 'French' },
            { code: 'de', name: 'German' },
            { code: 'el', name: 'Greek' },
            { code: 'hu', name: 'Hungarian' },
            { code: 'it', name: 'Italian' },
            { code: 'ja', name: 'Japanese' },
            { code: 'pl', name: 'Polish' },
            { code: 'pt', name: 'Portuguese' },
            { code: 'ru', name: 'Russian' },
            { code: 'es', name: 'Spanish' },
            { code: 'sv', name: 'Swedish' },
            { code: 'tr', name: 'Turkish' }
        ],

        tableOption: {
            perPage: 10,
            perPageValues: [5, 10, 20, 50],
            skin: 'table table-hover',
            columnsClasses: { actions: 'actions text-center' },
            sortable: [],
            pagination: { nav: 'scroll', chunk: 5 },
            texts: {
                noResults: 'Nenhum registro encontrado',
                count: 'Exibindo {from} para {to} de {count}',
                filter: '',
                filterPlaceholder: 'Pesquisar',
                limit: 'Resultados:'
            },
            resizableColumns: false
        },

        pieOptions: {
            chart: {
                toolbar: {
                    show: true,
                    tools: { download: true }
                }
            },
            legend: { position: 'bottom' },
            labels: []
        },

        barOptions: {
            chart: {
                toolbar: {
                    show: true,
                    tools: { download: true }
                }
            },
            dataLabels: {
                enabled: false,
                formatter: function(value) {
                    return formatNumberBr(value);
                }
            },
            stroke: { show: true, width: 2, colors: ['transparent'] },
            colors: ['#5c1ac3', '#ffbb44'],
            dropShadow: { enabled: true, opacity: 0.3, blur: 1, left: 1, top: 1, color: '#515365' },
            plotOptions: { 
                bar: { horizontal: false, columnWidth: '55%', borderRadius: 10, dataLabels: {position: 'top'} }
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '14px',
                markers: { width: 12, height: 12 },
                itemMargin: { horizontal: 10, vertical: 10 }
            },
            grid: { borderColor: '#e0e6ed' },
            xaxis: {
                categories: [],
                axisBorder: { show: true, color: '#e0e6ed' }
            },
            yaxis: {
                labels: {
                    formatter: function(value) {
                        return formatNumberBr(value);
                    }
                }
            },
            tooltip: {
                theme: 'light',
                y: {
                    formatter: function(val) {
                        return formatNumberBr(val);
                    }
                }
            }
        }
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = value || 'en';
            i18n.global.locale = value;
            localStorage.setItem('i18n_locale', value);
            state.locale = value;
        },

        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'light';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } else if (value == 'system') {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        }
    },
    getters: {
        layout(state) {
            return state.layout;
        }
    },
    actions: {
        async getCep({ dispatch, commit }, cep) {
            try {
                const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`, {
                    headers: { Authorization: null }
                });

                if (data?.erro) {
                    const toast = useToast();
                    toast.warning('CEP não encontrado.');
                    return null;
                }

                return data;
            } catch (e) {
                throw e;
            }
        },

        async getCnpj({ dispatch, commit }, cnpj) {
            try {
                cnpj = justNumbers(cnpj);
                const { data } = await axios.get(`https://cnpj.deppes.com/cnpj/${cnpj}`, {
                    headers: { Accept: 'Application/json' }
                });

                return data;
            } catch (e) {
                handleAxiosError(e);
            }
        }
    },

    modules: {
        user,
        positionDescription,
        descriptionAnalysis,
        suitabilityTable,
        salarySurvey,
        collaborators,
        positions,
        sectors,
        evaluations,     
        relationalNps,  
        transactionalNps,      
        myReviews,
    }
});
