<template>
    <div class='layout-px-spacing'>
        <bread-crumb :data='[{page: "Avaliação de Desempenho", active: true}, {page: "Ranking", active: true}]' />
        <load :load='load' />

        <div class='row layout-top-spacing'>
            <div class="d-flex justify-content-between align-items-center mb-3">
                <h5>Ranking dos Colaboradores</h5>

                <app-button
                    @click.prevent="showFilter = true"
                    icon="fa-solid fa-filter" text="Filtro" />
            </div>

            <ranking-filters 
                v-model='showFilter'
                :positions="positions"
                :sectors='sectors' 
                :companies='companies' 
                @onFilter='onFilter' 
            />

            <div class='col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing'>
                <div class='panel br-4'>
                    <div class='panel-body'>

                        <div class='table-responsive'>
                            <table class='table table-striped table-bordered'>
                                <thead>
                                <tr>
                                    <th class='text-center'>Posição</th>
                                    <th>Nome</th>
                                    <th>Unidade</th>
                                    <th>Setor</th>
                                    <th class='text-center'>Percentual</th>
                                    <th class='text-center'>Critério</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for='(ranking, index) in collaborators.data' :key='`collaborators-${index}`'>
                                    <td class='text-center'>{{ ranking.rank }}º</td>
                                    <td>
                                        {{ ranking.collaborator }} <br>
                                        <span class="text-muted">{{ ranking.position }}</span>
                                    </td>
                                    <td>{{ ranking.company }}</td>
                                    <td>{{ ranking.sector }}</td>
                                    <td class='text-center'>{{ formatPercent(ranking.average_score * 10) }}</td>
                                    <td v-tooltip='ranking.decision_criteria.feedback' class='text-white text-center'
                                        :style='{background: ranking.decision_criteria.color}'>
                                        <b>{{ ranking.decision_criteria.status }}</b>
                                    </td>
                                </tr>
                                <tr v-if='!collaborators?.data?.length'>
                                    <td colspan='6' class='text-center bg-light'>
                                        Nenhum registro encontrado.
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <paginate :data='collaborators' @onPaginate='onPaginate' @onTotalPages='onTotalPages' />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import BreadCrumb from '@/components/BreadCrumb.vue';
    import { onMounted, ref, computed } from 'vue';
    import { formatPercent, handleAxiosError } from '@/helpers';
    import { useMeta } from '@/composables/use-meta';
    import Paginate from '@/components/Paginate.vue';
    import { useToast } from 'vue-toastification';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import RankingFilters from '@/views/evaluation/ranking/ranking-filters.vue';
    import Load from '@/components/Load.vue';
    import moment from 'moment';
    import AppButton from '@/components/AppButton.vue';

    const collaborators = ref([]);
    const sectors = ref([]);
    const companies = ref([]);
    const positions = ref([]);
    const load = ref(false);
    const totalPages = ref(15);
    const page = ref(1);
    const search = ref('');
    const orderTable = ref('');
    const formFilter = ref('');
    const toast = useToast();
    const showFilter = ref(false);

    onMounted(async () => {
        load.value = true;
        formFilter.value = `&start_date=${moment().startOf('year').format('YYYY-MM-DD')}&end_date=${moment().endOf('month').format('YYYY-MM-DD')}`;

        await Promise.all([
            getRanking(),
            getCompanies(),
            getSectors(),
            getPositions()
        ]);

        load.value = false;
    });

    const onFilter = async (value = '') => {
        page.value = 1;
        formFilter.value = value;
        load.value = true;
        await getRanking();
        load.value = false;
    };

    const onTotalPages = async (value) => {
        totalPages.value = value;
        page.value = 1;
        load.value = true;
        await getRanking();
        load.value = false;
    };

    const onPaginate = async (value) => {
        page.value = value;
        load.value = true;
        await getRanking();
        load.value = false;
    };

    const getSectors = async () => {
        try {
            const { data } = await axios.get(`sectors?orderBy[name]=ASC`);
            sectors.value = data;
        } catch (e) {
            handleAxiosError(e);
        }
    };

    const getCompanies = async () => {
        try {
            const { data } = await axios.get(`companies?orderBy[name]=ASC`);
            companies.value = data;
        } catch (e) {
            handleAxiosError(e);
        }
    };

    const getPositions = async () => {
        try {
            const { data } = await axios.get(`positions?orderBy[name]=ASC`);
            positions.value = data;
        } catch (e) {
            handleAxiosError(e);
        }
    };
    
    const getRanking = async () => {
        try {
            const endPoint = `evaluation-histories/ranking?${filters.value}`;
            const { data } = await axios.get(endPoint);
            collaborators.value = data;
        } catch (e) {
            handleAxiosError(e);
        }
    };

    const filters = computed(() => {
        let endpoint = `totalPage=${totalPages.value}&page=${page.value}${formFilter.value}`;
        if (search.value) {
            endpoint += `&name=${search.value}`;
        }

        return endpoint;
    });

    useMeta({ title: 'Ranking' });
</script>

<style scoped>
    @media (min-width: 768px) {
        .table-responsive {
            overflow: inherit;
        }
    }
</style>
