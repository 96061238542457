import store from '@/store';
import moment from 'moment';

export const handleAxiosError = (error) => {
    if (error.response?.status === 401) {
        store.commit('user/setUser', null);
        store.commit('user/setToken', null);

        window.location.href = '/login';
    } else if (error.response?.status === 500) {
        Swal.fire('Falha interna', 'Aconteceu um erro inesperado, nossa equipe foi alertada.', 'error');
    } else if (error.response?.status === 404) {
        Swal.fire('Ooops', 'Objeto não encontado.', 'warning');
    } else if (error.response?.status === 400) {
        Swal.fire('Ação Negada', error.response.data.message, 'warning');
    } else if (error.response?.status === 429) {
        Swal.fire('Muitas tentativas', 'Foram feitas muitas tentativas, aguarde 3 minutos para tentar novamente.', 'warning');
    } else if (error?.code === 'ERR_NETWORK') {
        Swal.fire('Falha na conexão', 'Falha ao tentar conectar com API', 'warning');
    } else {
        console.error(error);
    }
};

export const sortByKey = (array, key) => {
    return array.sort((a, b) => {
        const valueA = a[key];
        const valueB = b[key];

        if (valueA < valueB) {
            return -1;
        } else if (valueA > valueB) {
            return 1;
        } else {
            return 0;
        }
    });
};

export const justNumbers = (text) => {
    const regex = /\d+/g;
    return text.match(regex).join('');
};

export const evaluationHandleAxiosError = (error, uuid, type = 'evaluated') => {
    if (error.response?.status === 401) {
        localStorage.removeItem('_evaluation_token');
        localStorage.removeItem('_evaluation_name');
        localStorage.removeItem('_evaluation_avatar');

        if (type === 'evaluated') {
            window.location.href = '/avaliacao/avaliado/login/' + uuid;
        } else if (type === 'consensual') {
            window.location.href = '/avaliacao-consensual/avaliador/login/' + uuid;
        } else {
            window.location.href = '/avaliacao/avaliador/login/' + uuid;
        }
    } else if (error.response?.status === 500) {
        Swal.fire('Falha interna', 'Aconteceu um erro inesperado, nossa equipe foi alertada.', 'error');
    } else if (error.response?.status === 404) {
        Swal.fire('Ooops', 'Objeto não encontado.', 'warning');
    } else if (error.response?.status === 400) {
        Swal.fire('Ação Negada', error.response.data.message, 'warning');
    } else if (error.response?.status === 429) {
        Swal.fire('Muitas tentativas', 'Foram feitas muitas tentativas, aguarde 3 minutos para tentar novamente.', 'warning');
    } else {
        console.error(error);
    }
};

export const getParameterUrl = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const mask = (value, mask) => {
    value = value.toString();

    let maskedValue = '';
    let valueIndex = 0;

    for (let i = 0; i < mask.length; i++) {
        const maskChar = mask[i];

        if (maskChar === '#') {
            maskedValue += value[valueIndex] || '';
            valueIndex++;
        } else {
            maskedValue += maskChar;
        }
    }

    return maskedValue;
};

export const onModalDelete = (id, name, message = null) => {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-danger me-3',
            cancelButton: 'btn btn-default'
        },

        buttonsStyling: false
    });

    let html = `Deseja realmente deletar: <span class='text-danger'>${name}?</span>`;
    if (message) html += `<br><br>${message}`;

    return swalWithBootstrapButtons.fire({
        title: 'Tem certeza?',
        html: html,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim, deletar!'
    });

};

export const modalConfirm = (message, confirmButtonText = 'Sim', cancelButtonText = 'Cancelar') => {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success me-3',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    });

    return swalWithBootstrapButtons.fire({
        title: 'Tem certeza?',
        html: message,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText
    });
};

export const initTooltip = () => {
    setTimeout(() => {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map((tooltipTriggerEl) => {
            const tooltip = bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl);
            tooltipTriggerEl.addListener('click', () => {
                tooltip.hide();
            });

            return new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
    }, 10);
};

export const initPopover = () => {
    setTimeout(() => {
        const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
        const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl));
    }, 10);
};

export const strAscii = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-zA-Z0-9\s]/g, '');
};

export const formatDate = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY');
    }

    return date;
};

export const formatDateTime = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY HH:mm');
    }

    return date;
};

export const getGender = (gender) => {
    if (gender === 'M') {
        return 'Masculino';
    } else if (gender === 'F') {
        return 'Feminino';
    } else if (gender === 'N') {
        return 'Não binário';
    } else if (gender === 'MC') {
        return 'Mulher Cis';
    } else if (gender === 'MT') {
        return 'Mulher Trans / Travesti';
    } else if (gender === 'HC') {
        return 'Homem Cis';
    } else if (gender === 'HT') {
        return 'Homem Trans';
    } else if (gender === 'O') {
        return 'Outros';
    } else {
        return gender;
    }
};

export const getRace = (race) => {
    if (race === 'AMARELO') {
        return 'Amarelo (a)';
    } else if (race === 'BRANCO') {
        return 'Branco (a)';
    } else if (race === 'INDIGENA') {
        return 'Indígena';
    } else if (race === 'PARDO') {
        return 'Pardo (a)';
    } else if (race === 'PRETO') {
        return 'Preto (a)';
    } else {
        return race;
    }
};

export const getMaritalStatus = (gender) => {
    if (gender === 'SOLTEIRO') {
        return 'Solteiro';
    }
    if (gender === 'CASADO') {
        return 'Casado';
    }
    if (gender === 'SEPARADO') {
        return 'Separado';
    }
    if (gender === 'DIVORCIADO') {
        return 'Divorciado';
    }
    if (gender === 'UNIAO_ESTAVEL') {
        return 'União Estável';
    }
    if (gender === 'VIUVO') {
        return 'Viúvo';
    }

    return gender;
};

export const formatTime = (date) => {
    return moment(date).format('HH:mm');
};

export const generateId = (value, index = Math.floor(Math.random() * (9999999 - 10 + 1)) + 10) => {
    return strAscii(value).toLowerCase().replaceAll(' ', '_') + '_' + index;
};

export const formatPercent = (number) => {
    if (number !== null || number !== undefined) {
        return new Intl.NumberFormat('pt-BR', { style: 'decimal', maximumFractionDigits: 2 }).format(number) + '%';
    }

    return number;
};

export const formatNumberBr = (number) => {
    if (number !== null || number !== undefined) {
        return new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        }).format(number);
    }

    return number;
};

export const formatMoney = (number) => {
    if (number || number === 0) {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 2
        }).format(number);
    }

    return number;
};

export const calculateLinearTrendLine = (x, y) => {
    const n = x.length;
    let sumX = 0;
    let sumY = 0;
    let sumXY = 0;
    let sumX2 = 0;

    for (let i = 0; i < n; i++) {
        sumX += x[i];
        sumY += y[i];
        sumXY += x[i] * y[i];
        sumX2 += x[i] * x[i];
    }

    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;
    const trendLine = [];

    for (let i = 0; i < n; i++) {
        trendLine.push({ x: parseFloat((x[i]).toFixed(2)), y: parseFloat((slope * x[i] + intercept).toFixed(2)) });
    }

    return trendLine;
};

export const initialsName = (name) => {
    if (name) {
        const dataLastName = name.split(' ');
        const lastName = dataLastName[dataLastName.length - 1];
        return name.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
    }

    return name;
};

export const generateStrongPassword = (length) => {
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';
    const symbolChars = '!@#$%^&*()_+~`|}{[]\:;?><,./-=';
    const allChars = uppercaseChars + lowercaseChars + numberChars + symbolChars;

    let password = '';
    for (let i = 0; i < length; i++) {
        password += allChars.charAt(Math.floor(Math.random() * allChars.length));
    }

    return password;
};

export const getDistinctObjects = (array, props) => {

    const distinctArray = [];
    const ids = [];

    array.forEach(obj => {
        if (!ids.includes(obj[props])) {
            distinctArray.push(obj);
            ids.push(obj[props]);
        }
    });

    distinctArray.sort((a, b) => a.empresa.localeCompare(b.empresa));
    return distinctArray;
};

export const groupArray = (array, prop) => {
    const group = {};
    array?.forEach(item => {
        const category = item[prop];
        if (!group[category]) {
            group[category] = [];
        }

        group[category].push(item);
    });
    return group;
};

export const isNumber = (value) => {
    const pattern = /^-?\d+(\.\d+)?$/;
    if (typeof value === 'number') return true;
    return pattern.test(value);
};

export const compareArrayValues = (array, compare) => {
    if (array.length !== compare.length) return false;
    const setArray = new Set(array);
    const setCompare = new Set(compare);

    if (setArray.size !== setCompare.size) return false;
    for (let item of setArray) {
        if (!setCompare.has(item)) {
            return false;
        }
    }
    return true;
};

export const unaccent = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');


/**
 * @param {string} periodType
 * @param {number} value - Mês ou Ano;
 * @return {string}
 * */

export const encodeAmpersand = (str) => str.replace('&', '%26');
export const decodeAmpersand = (str) => str.replace('%26', '&');

export const getPeriodByType = (periodType, month) => {
    let period = '';

    if (periodType === 'MENSAL') {
        period = moment().month(month - 1).format('YYYY-MM-DD');
    } else if (periodType === 'ANUAL') {
        period = moment().year(month).format('YYYY-MM-DD');
    } else if (periodType === 'BIMESTRAL') {
        if (month >= 1 && month <= 2) period = `1° Bimestre`;
        else if (month >= 3 && month <= 4) period = `2° Bimestre`;
        else if (month >= 5 && month <= 6) period = `3° Bimestre`;
        else if (month >= 7 && month <= 8) period = `4° Bimestre`;
        else if (month >= 9 && month <= 10) period = `5° Bimestre`;
        else period = `6° Bimestre`;
    } else if (periodType === 'TRIMESTRAL') {
        if (month >= 1 && month <= 3) period = `1° Trimestre`;
        else if (month >= 4 && month <= 6) period = `2° Trimestre`;
        else if (month >= 7 && month <= 9) period = `3° Trimestre`;
        else period = `4° Trimestre`;

    } else if (periodType === 'SEMESTRAL') {
        if (month >= 1 && month <= 6) period = `1° Semestre`;
        else period = `2° Semestre`;
    }
    return period;
};

export const convertNumericPeriodToString = (periodType, numericPeriod) => {
    if (periodType === 'BIMESTRAL') {
        if (numericPeriod === 1) return `1° Bimestre`;
        else if (numericPeriod === 2) return `2° Bimestre`;
        else if (numericPeriod === 3) return `3° Bimestre`;
        else if (numericPeriod === 4) return `4° Bimestre`;
        else if (numericPeriod === 5) return `5° Bimestre`;
        else if (numericPeriod === 6) return `6° Bimestre`;
    } else if (periodType === 'TRIMESTRAL') {
        if (numericPeriod === 1) return `1° Trimestre`;
        else if (numericPeriod === 2) return `2° Trimestre`;
        else if (numericPeriod === 3) return `3° Trimestre`;
        else if (numericPeriod === 4) return `4° Trimestre`;
    } else if (periodType === 'SEMESTRAL') {
        if (numericPeriod === 1) return `1° Semestre`;
        else if (numericPeriod === 2) return `2° Semestre`;
    }
};

export const convertPeriodToDate = (period) => {
    const currentDate = moment();
    const currentMonth = currentDate.month() + 1;
    let month = getPeriodRange(period)
    
    const monthStart = month[0];
    const monthEnd = month[month.length - 1];

    if (currentMonth < monthStart) month = monthStart;
    else if (currentMonth > monthEnd) month = monthEnd;
    else month = currentMonth;

    return currentDate.month(month - 1).format('YYYY-MM-DD');
};

export const getPeriodRange = (period) => {
    if (period === `1° Bimestre`) {
        return [1, 2];
    } else if (period === `2° Bimestre`) {
        return [3, 4];
    } else if (period === `3° Bimestre`) {
        return [5, 6];
    } else if (period === `4° Bimestre`) {
        return [7, 8];
    } else if (period === `5° Bimestre`) {
        return [9, 10];
    } else if (period === `6° Bimestre`) {
        return [11, 12];
    } else if (period === `1° Trimestre`) {
        return [1, 3];
    } else if (period === `2° Trimestre`) {
        return [4, 6];
    } else if (period === `3° Trimestre`) {
        return [7, 9];
    } else if (period === `4° Trimestre`) {
        return [10, 12];
    } else if (period === `1° Semestre`) {
        return [1, 6];
    } else if (period === `2° Semestre`) {
        return [7, 12];
    }
    return []
};

export const paginateArray = function(array, currentPage, perPage) {
    if(!array?.length) return null;
    
    currentPage = parseInt(currentPage);
    perPage = parseInt(perPage);
    
    let links = [];
    const totalItems = array.length ?? 0;
    const totalPages = Math.ceil(totalItems / perPage);
    const beforePage = currentPage - 1 === 0 ? null : `?page=${currentPage - 1}`;
    const nextPage = currentPage < totalPages ? `?page=${currentPage + 1}` : null;
    const start = (currentPage - 1) * perPage;
    const end = start + perPage;
    const itemsPage = array.slice(start, end);
    
    for (let i = 1; i <= totalPages; i++) links.push(i);
    links = links.map(num => ({ url: `?page=${num}`, label: num, active: num === currentPage }));
    links.unshift({ "url": beforePage, "label": "&laquo; Anterior", "active": false });
    links.push({ "url": nextPage, "label": "Pr\u00f3ximo &raquo;", "active": false });
    
    return {
        data: itemsPage,
        current_page: currentPage,
        per_page: perPage,
        total: totalItems,
        last_page: totalPages,
        first_page_url: `?page=1`,
        last_page_url: `?page=${totalPages}`,
        next_page_url: nextPage,
        prev_page_url: currentPage > 1 ? `?page=${currentPage - 1}` : null,
        from: start + 1,
        to: Math.min(end, totalItems),
        links: links
    };
}

export const isDate = (string) => {
    const data = new Date(string);
    return !isNaN(data.getTime());
}

export const getFontColorByLuminosity = (hexadecimalColor) => {
    if(!hexadecimalColor) return;
    const r = parseInt(hexadecimalColor.substr(1, 2), 16);
    const g = parseInt(hexadecimalColor.substr(3, 2), 16);
    const b = parseInt(hexadecimalColor.substr(5, 2), 16);
    const luminosity = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    
    return luminosity > 128 ? "#212121" : "#ffffff";
}

export const isEmail = (email) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
}

export const isCpf = (cpf) => {
    cpf = justNumbers(cpf);
    
    if (cpf.length !== 11 || cpf === cpf[0].repeat(11)) return false;
    
    let sum = 0;
    
    for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf[i]) * (10 - i);
    }
    let d1 = (sum * 10) % 11;
    if (d1 === 10) d1 = 0;
    
    sum = 0;
    for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf[i]) * (11 - i);
    }
    let d2 = (sum * 10) % 11;
    if (d2 === 10) d2 = 0;
    
    return cpf[9] == d1 && cpf[10] == d2;
}
