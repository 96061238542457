<template>
  <div class='form-group'>
    <label v-if='label'>{{ label }} <span v-if='required' class='text-danger'>*</span> </label>
    <vue-multiselect
      :placeholder='placeholder'
      selected-label='Selecionado'
      select-label=''
      deselect-label='Remover seleção'
      :label='optionLabel'
      v-model='valueSelect'
      :value='modelValue'
      :track-by='optionValue'
      @select='updateValue'
      @remove='onRemove'
      @search-change='onSearch'
      :custom-label='customLabel'
      :loading='loading'
      :disabled='disabled'
      :multiple='true'
      :taggable='false'
      :options='options'
      :close-on-select="closeOnSelect"
      :clear-on-select="clearOnSelect"
      :preserve-search="preserveSearch"
      :preselect-first="preselectFirst"
    >

      <template #option='props' v-if='subItem'>
        {{ props.option[optionLabel] }} <br>
        <small style='font-weight: bold'>{{ subItem(props.option) }}</small>
      </template>

      <template #noOptions>
        Listagem vazia
      </template>

      <template #noResult>
        Nenhum registro encontrado
      </template>
    </vue-multiselect>

    <div class='typo__label form__label mt-1' v-if='errors && errors.length'
         v-for='(error, index) in errors' :key='`invalid-feedback-${index}`'>
      <span class='error-select2'>{{ error }}</span>
    </div>

  </div>
</template>

<script setup>
  import VueMultiselect from 'vue-multiselect';
  import { ref, onUpdated, onMounted } from 'vue';

  onMounted(() => {
    valueSelect.value = props.modelValue;
  });

  onUpdated(() => {
    valueSelect.value = props.modelValue;
  });

  const emit = defineEmits(['update:modelValue', 'onChange', 'onSearch']);
  const valueSelect = ref(null);

  const props = defineProps({
    label: { type: String, default: null },
    placeholder: { type: String, default: '--Selecione--' },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    errors: { default: null },
    modelValue: { default: null },
    type: { type: String, default: 'text' },
    options: { type: Array, default: [] },
    optionLabel: { type: String, default: 'name' },
    optionCustomLabel: { default: null },
    optionValue: { type: String, default: 'id' },
    subItem: { default: null }, 
    closeOnSelect: { type: Boolean, default: false },
    clearOnSelect: { type: Boolean, default: true },
    preserveSearch: { type: Boolean, default: false },
    preselectFirst: { type: Boolean, default: false },
  });

  const customLabel = (option) => {
    if (props.optionCustomLabel === null) {
      return option[props.optionLabel];
    }

    return props.optionCustomLabel(option);
  };

  const onSearch = (value) => {
    emit('onSearch', value);
  };

  const onRemove = (selectedOption) => {
    emit('update:modelValue', valueSelect.value);
    emit('onChange', valueSelect.value);
  };

  const updateValue = (selectedOption) => {
    emit('update:modelValue', valueSelect.value);
    emit('onChange', valueSelect.value);
  };
</script>
