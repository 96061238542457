<template>
    <div class='row' v-if='links.length && data'>
        <div class='col-sm-4'>
            <select class='form-select form-select-sm border-0 br-50' @change='onTotalPages'
                    v-model='totalPageComputed' v-if='totalPage'
                    style='max-width: 220px; background-color: #e0e6ed; color: #3b3f5c; font-weight: bold; cursor: pointer; height: 38px'>
                <option :value='15'>15 Linhas por página</option>
                <option :value='25'>25 Linhas por página</option>
                <option :value='50'>50 Linhas por página</option>
                <option :value='100'>100 Linhas por página</option>
            </select>

            <select class='form-select form-select-sm border-0 br-50' @change='onTotalPages' v-else
                    style='max-width: 220px; background-color: #e0e6ed; color: #3b3f5c; font-weight: bold; cursor: pointer; height: 38px'>
                <option :value='15'>15 Linhas por página</option>
                <option :value='25'>25 Linhas por página</option>
                <option :value='50'>50 Linhas por página</option>
                <option :value='100'>100 Linhas por página</option>
            </select>
        </div>
        <div class='col-sm-4 mt-2 text-center'>
            Total de registros: {{ data.total }}
        </div>
        <div class='col-sm-4 d-flex justify-content-end'>
            <div
                class='paginating-container pagination-no_spacing flex-column align-items-center'>
                <ul role='menubar' aria-disabled='false' aria-label='Pagination'
                    class='pagination mb-2 b-pagination'>
                    <li role='presentation' class='page-item first'>
                        <button type='button' tabindex='-1' v-if='data?.first_page_url' class='page-link'
                                :disabled='data.current_page == getParameterUrl("page", data?.first_page_url)'
                                @click.prevent='onClick(getParameterUrl("page", data?.first_page_url))'>
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'
                                 fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                <path stroke-linecap='round' stroke-linejoin='round'
                                      stroke-width='2' d='M11 19l-7-7 7-7m8 14l-7-7 7-7'></path>
                            </svg>
                        </button>
                    </li>
                    <li role='presentation' class='page-item prev' v-if='links.length'>
                        <button type='button' :disabled='!links[0]["url"]' class='page-link'
                                @click.prevent='onClick(getParameterUrl("page", links[0]["url"]))'>
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'
                                 fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                <path stroke-linecap='round' stroke-linejoin='round'
                                      stroke-width='2' d='M15 19l-7-7 7-7'></path>
                            </svg>
                        </button>
                    </li>
                    <li role='presentation' class='page-item'>
                        <button type='button' class='page-link'>
                            {{ data?.current_page }} / {{ data?.last_page }}
                        </button>
                    </li>
                    <li role='presentation' class='page-item next'>
                        <button type='button' v-if='links[lastIndexLinks]'
                                :disabled='!links[lastIndexLinks]["url"]'
                                @click.prevent='onClick(getParameterUrl("page", links[lastIndexLinks]["url"]))'
                                class='page-link'>
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'
                                 fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                <path stroke-linecap='round' stroke-linejoin='round'
                                      stroke-width='2' d='M9 5l7 7-7 7'></path>
                            </svg>
                        </button>
                    </li>
                    <li role='presentation' class='page-item last'>
                        <button role='menuitem' type='button' tabindex='-1' @click.prevent='onClick(data.last_page)'
                                :disabled='data.current_page == data?.last_page'
                                aria-label='Go to last page' class='page-link' v-if='data?.last_page'>
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'
                                 fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                <path stroke-linecap='round' stroke-linejoin='round'
                                      stroke-width='2' d='M13 5l7 7-7 7M5 5l7 7-7 7'></path>
                            </svg>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, onMounted, ref } from 'vue';
    import { getParameterUrl } from '@/helpers';

    const emit = defineEmits(['onPaginate', 'onTotalPages', 'update:totalPage']);

    const props = defineProps({
        data: { type: Object, require: true },
        totalPage: { default: null }
    });

    const totalPageComputed = computed({
        get() {
            return props.totalPage;
        },
        set(value) {
            emit('update:totalPage', value);
        }
    });

    const onClick = async (page) => {
        emit('onPaginate', page);
    };

    const onTotalPages = async (event) => {
        emit('update:totalPage', event.target.value);
        emit('onTotalPages', event.target.value);
    };

    const lastIndexLinks = computed(() => {
        return links.value.length - 1;
    });

    const centerLinks = computed(() => {
        const currentPage = props.data.current_page;
        const lastPage = props.data.last_page;
        let dataIndex = [1, 2, 3];

        if (currentPage == lastPage) {
            dataIndex = [currentPage - 2, currentPage - 1, currentPage];
        } else if (currentPage != 1) {
            dataIndex = [currentPage - 1, currentPage, currentPage + 1];
        }

        return links.value.filter(link => dataIndex.find(item => item == link.label));
    });

    const links = computed(() => {
        return props.data?.links ?? [];
    });
</script>

